.navb{
    display:flex;
    justify-content: center;
    align-items: center;
    background-color:#262525;
    color: white;
    
    position:sticky;
    z-index: 100;
    top: 0;
}

.stic{
    position: sticky;
    z-index: 100;
    top: 0;
}
.navb-menu{
    display: flex;
    margin-right: 5rem;
    list-style: none;
    color: white;
    text-decoration: none;
}
.navb-menu li{
    text-decoration: none;
    padding: 0 3rem;
    color: white;
}


.event,.home,.sponser,.entre,.contact,.about,.equipment,.team{
    text-decoration: none;
    color: rgb(255, 255, 255);
    font-size: 18px;
    font-weight: 600;
    padding: 10px 20px;
    margin: 0 10px;
    cursor: pointer;
    transition: all 0.1s ease;
}
.event:hover, .contact:hover, .entre:hover,.home:hover,.sponser:hover,.about:hover,.equipment:hover, .team:hover{
    background-color: white;
    color: black;
    border: 1px solid;
    border-radius: 10px;
    
}


.event:active,.contact:active,.entre:active,.home:active,.sponser:active,.about:active,.equipment:active, .team:active{
    transform: scale(0.10);
  }



  @media screen and (max-width:1025px) {
    .navb{
        display:flex;
        justify-content: center;
        align-items: center;
        background-color:#262525;
        color: white;
        width: 1024px;
        height: 80px;
        position:sticky;
        z-index: 100;
        top: 0;
        margin-right: 5rem;
        
    }
    
    .navb-menu{
        display: flex;
        margin-right: 5rem;
        list-style: none;
        color: white;
        text-decoration: none;

    }
    .navb-menu li{
        text-decoration: none;
        padding: 0 1rem;
        color: white;
    }
    
    
    .event,.home,.sponser,.entre,.contact,.about,.equipment,.team{
        text-decoration: none;
        color: rgb(255, 255, 255);
        font-size: 18px;
        font-weight: 600;
        padding: 10px 20px;
        margin: 0 10px;
        cursor: pointer;
        transition: all 0.1s ease;
    }
    .event:hover, .contact:hover, .entre:hover,.home:hover,.sponser:hover,.about:hover,.equipment:hover, .team:hover{
        background-color: white;
        color: black;
        border: 1px solid;
        border-radius: 10px;
        
    }
    
    
    .event:active,.contact:active,.entre:active,.home:active,.sponser:active,.about:active,.equipment:active{
        transform: scale(0.10);
      }
    
    

  }


