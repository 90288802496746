.header{
    width: 100%;
    height:120px;
    color: #220877;
    display: flex;
    text-align: center;
    justify-content:center;
    background-color:white;
    border-bottom:  solid #262525 2px;
}

.clgname{
    margin-right: 8rem;
    margin-left: 12rem;
}

.h3{
    font-size: 1rem;
    text-decoration: uppercase;
    font-size: 30px;
    font-weight: 500;
    width: 100%;
    line-height: 30px;

}
.p{
    margin-top: 1rem;
    font-size: 1rem;
    text-decoration: uppercase;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
}
.q{
    font-size: 1rem;
    font-family: 'monserrat', sans-serif;
    text-decoration: uppercase;
    font-size: 16px;
    font-weight: 200;
    line-height: 30px;
}
.image{
    width:100px;
    height:100px;
    margin-top: 0.21rem;

}
.image2{
    width:200px;
    height:100px;
    margin-top: 0.21rem;
    
}
@media screen and (min-width:1024px) and (max-width:1280px) {
    .clgname{
        margin-right: 8rem;
        margin-left: 13rem;
    }
    
    .h3{
        font-size: 1rem;
        text-decoration: uppercase;
        font-size: 30px;
        font-weight: 500;
        width: 100%;
        line-height: 30px;
    
    }
    .p{
        margin-top: 1rem;
        font-size: 1rem;
        text-decoration: uppercase;
        font-size: 20px;
        font-weight: 400;
        line-height: 30px;
    }
    .q{
        font-size: 1rem;
        font-family: 'monserrat', sans-serif;
        text-decoration: uppercase;
        font-size: 16px;
        font-weight: 200;
        line-height: 30px;
    }
    .image{
        width:100px;
        height:100px;
        margin-top: 0.21rem;
        
    
    }
    .image2{
        width:200px;
        height:100px;
        margin-top: 0.21rem;
        
    }
}

@media screen and (min-width:950px) and (max-width:1024px) {
    .header{
        text-align: center;
    }
    .clgname{
        margin-right: 4rem;
        margin-left: 9rem;
    }
    .clglogo{
        margin-left: 0.5rem;
    }
    .image{
        width:100px;
        height:100px;
        margin-top: 1rem;
        margin-left: 1rem;
    
    }
    .image2{
        width:170px;
        height:80px;
        margin-top: 1.5rem;
    }
    
.h3{
    font-size: 25px;
}
.p{
    font-size: 15px;
    font-weight: 400;
}
.q{
    font-size: 13px;
    font-weight: 400;
    line-height: 10px;
}
}


@media screen and (min-width:821px) and (max-width:950px) {
    .header{
        text-align: center;
    }
    .clgname{
        margin-right: 2rem;
        margin-left: 4rem;
    }
    .clglogo{
        margin-left: 0.5rem;
  
    }
    .image{
        width:100px;
        height:100px;
        margin-top: 1rem;
        margin-left: 1rem;
    
    }
    .image2{
        width:150px;
        height:75px;
        margin-top: 1.5rem;
        margin-right: 1rem;
    }
    
.h3{
    font-size: 25px;
}
.p{
    font-size: 15px;
    font-weight: 400;
}
.q{
    font-size: 13px;
    font-weight: 400;
    line-height: 10px;
}
}

@media screen and (min-width:768px) and (max-width:821px) {
    .header{
        text-align: center;
    }
    .clgname{
        margin-right: 3rem;
        margin-left: 7rem;
    }
    .clglogo{
        margin-left: 0.5rem;
    }
    .image{
        width:100px;
        height:100px;
        margin-top: 1rem;
        margin-left: 1rem;
    
    }
    .image2{
        width:170px;
        height:80px;
        margin-top: 1.5rem;
    }
    
.h3{
    font-size: 26px;
}
.p{
    font-size: 15px;
    font-weight: 400;
}
.q{
    font-size: 13px;
    font-weight: 400;
    line-height: 10px;
}
}



@media screen and (max-width:840px) {
    .header{
        text-align: center;
    }
    .clgname{
        margin-right: 0rem;
        margin-left: 0rem;
    }
    .clglogo{
        margin-left: 1rem;
    }
    .image{
        width:80px;
        height:80px;
        margin-top: 1.4rem;
    
    }
    .image2{
        width:130px;
        height:65px;
        margin-top: 1.5rem;
    }
    
.h3{
    font-size: 22px;
}
.p{
    font-size: 18px;
    font-weight: 400;
}
.q{
    font-size: 14px;
    font-weight: 400;
    line-height: 10px;
}
}

@media screen and (max-width:600px) {
    .header{
        text-align: center;
    }
    .clgname{
        margin-right: 0rem;
        margin-left: 0rem;
    }
    .clglogo{
        margin-left: 1rem;
    }
    .image{
        width:60px;
        height:60px;
        margin-top: 1.4rem;
    
    }
    .image2{
        width:90px;
        height:45px;
        margin-top: 1.5rem;
    }
    
.h3{
    font-size: 21px;
}
.p{
    font-size: 15px;
    font-weight: 400;
}
.q{
    font-size: 12px;
    font-weight: 400;
    line-height: 10px;
}
}
@media screen and (max-width:545px) {
    .header{
        text-align: center;
    }
    .clgname{
        margin-right: 0rem;
        margin-left: 0rem;
    }
    .clglogo{
        margin-left: 1rem;
    }
    .image{
        width:60px;
        height:60px;
        margin-top: 1.4rem;
    
    }
    .image2{
        width:90px;
        height:45px;
        margin-top: 1.5rem;
    }
    
.h3{
    font-size: 18px;
}
.p{
    font-size: 15px;
    font-weight: 400;
}
.q{
    font-size: 13px;
    font-weight: 400;
    line-height: 10px;
}
}
@media screen and (min-width:320px) and (max-width:426px) {
    .header{
        text-align: center;
        height: 80px;
    }
    .clgname{
        margin-top: 0.8rem;
        margin-right: 0rem;
        margin-left: 0rem;
    }
    .clglogo{
        margin-left: 0rem;
    }
    .image{
        width:40px;
        height:40px;
        margin-top: 1.4rem;
        margin-left: -3.5rem;
    
    }
    .image2{
        width:70px;
        height:35px;
        margin-top: 1.5rem;
        justify-content: end;
        align-self: flex-end;
        margin-right: -3.5rem;
    }
    
.h3{
    font-size: 12px;
    line-height: 15px;
    margin-left: 0rem;
    font-weight: 500;
}
.p{
    font-size: 11px;
    font-weight: 400;
    line-height: 10px;
}
.q{
    font-size: 7px;
    font-weight: 400;
    line-height: 0px;
}
}
