.work_container{
    width: 100%;
    min-height: 300px;
    border-top: solid #262525 0.8px;
    padding-right: 10rem;
    box-sizing: border-box;
}
.heading{
    text-align: center;
    color: rgb(255, 255, 255);
    background-color: #262525;
    width: 400px;
    height: 100px;
    font-family: 'Poppins', sans-serif;
    font-size:30px;
    font-weight: 600;
    -webkit-clip-path: polygon(100% 0, 78% 63%, 0 63%, 0 0);
    clip-path: polygon(100% 0, 78% 63%, 0 63%, 0 0);
}

.project_card{
    background: black;
    width: 110px;
    height: 110px;
    background:rgb(218, 216, 216);
    border-radius: 10px;
    box-shadow: 0px 1px 10px 1px black;
    border-radius: 50%;
    cursor: pointer;
    transition: 0.1s;
}

.project_card img{
    width: 100px;
    height: 100px;
    border-radius: 50%;
    transition: 0.1s;
    margin: 5px;
}

.project_card:hover{
    box-shadow: 0px 1px 10px 1px black;
    transform: matrix(1.2,0,0,1.2,0,0);
    transition: all 0.2s ease-in-out 0s;
    transform: scale(1.3s);
    z-index: 2;
}

.project_container_help{
    padding-left: 5rem;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(9,1fr);
    grid-gap: 40px;
    padding-bottom: 5rem;
}
@media screen and (min-width:1024px) and (max-width:1280px) {
    .project_container_help{
        padding-left: 10rem;
        grid-template-columns: repeat(5,1fr);
        grid-gap: 35px;
        padding-bottom: 5rem;
    }
}

@media screen and (min-width:800px) and (max-width:1024px) {
    .project_container_help{
        padding-left: 10rem;
        grid-template-columns: repeat(4,1fr);
        grid-gap: 35px;
        padding-bottom: 5rem;
    }
    .heading{
        width:300px;
        height:90px;
        font-size: 25px;
    }
}

@media screen and (min-width:768px) and (max-width:800px) {
    .project_container_help{
        padding-left: 10rem;
        grid-template-columns: repeat(5,1fr);
        grid-gap: 35px;
        padding-bottom: 5rem;
    }
    .heading{
        width:300px;
        height:90px;
        font-size: 25px;
    }
}

@media screen and (max-width:768px) {
    .project_container_help{
        padding-left:11rem;
        grid-template-columns: repeat(3,1fr);
        grid-gap: 35px;
        padding-bottom: 5rem;
    }
    .heading{
        width:300px;
        height:90px;
        font-size: 25px;
    }
}
@media screen and (max-width:600px) {
    .project_container_help{
        padding-left:6rem;
        grid-template-columns: repeat(3,1fr);
        grid-gap: 35px;
        padding-bottom: 5rem;
    }
    .heading{
        width:300px;
        height:90px;
        font-size: 25px;
    }
}
@media screen and (min-width:425px) and (max-width:426px) {
    .project_container_help{
        padding-left:5.5rem;
        grid-template-columns: repeat(2,1fr);
        grid-gap: 35px;
        padding-bottom: 5rem;
    }
    .heading{
        width:250px;
        height:80px;
        font-size: 20px;
    }
}
@media screen and (min-width:320px) and (max-width:425px) {
    .project_container_help{
        padding-left: 4.5rem;
        grid-template-columns: repeat(2,1fr);
        grid-gap: 35px;
        padding-bottom: 5rem;
    }
    .heading{
        width:200px;
        height:70px;
        font-size: 20px;
    }
}