.front_main{
    background-color:white;
    width: 100%;
    height: 700px;
    display: flex;
    justify-items: center;
    align-items: center;
    padding-bottom: 2rem;
}

.front{
    width: 80%;
    height: 600px;
    padding-left: 20rem;
    padding-top: 1rem;
}

.im1_front_{
    height: 600px;
    width: 900px;
    display: flex;
    justify-items: center;
    align-items: center;
    box-sizing: border-box;
    border-radius: 10px;
    box-shadow: 2px 5px 15px 5px black;
    
}

.im2{
    width: 100%;
    height: 633px;
    align-items: center;
    align-self: center;
}

@media screen and (min-width:1024px) and (max-width:1280px) {
    .outer{
        min-height: 800px;
    }
    .front_main{
        width: 100%;
        height: 700px;
    }
    
    .front{
        width: 100%;
        height: 600px;
        padding-left: 5rem;
        padding-right: 5rem;
        padding-top: 1rem;
        padding-bottom: 0.5rem;
    }
    
    .im2{
        width: 100%;
        height: 633px;
    }

    
.im1_front_{
    height: 650px;
    width: 700px;
    
}
}
@media screen and (min-width:768px) and (max-width:1024px) {
    
    .front_main{
        width: 100%;
        height: 700px;
        display: flex;
        justify-items: center;
        align-items: center;
        padding-bottom: 0;
    }
    
    .front{
        width: 100%;
        height: 600px;
        padding-left: 5rem;
        padding-right: 5rem;
        padding-top: 1rem;
        padding-bottom: 0.5rem;
    }
    
    .im2{
        width: 100%;
        height: 633px;
        align-items: center;
        align-self: center;
    }

    
.im1_front_{
    height: 550px;
    width: 700px;
    display: flex;
    justify-items: center;
    align-items: center;
    
}
}

@media screen and (min-width:540px) and (max-width:768px) {
    .outer{
        height: 500px;
    }
    .front_main{
        width: 100%;
        height: 700px;
        padding-bottom: 0;
    }
    .front{
        width: 100%;
        height: 600px;
        padding-top: 0rem;
    }
    
    .im2{
        width: 100%;
        height: 633px;
    }
    .im1_front_{
    height: 400px;
    width: 70%;
    }
}

@media screen and (min-width:425px) and (max-width:540px) {
    .outer{
        height: 350px;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }
    .front_main{
        width: 100%;
        height: 600px;
        padding-bottom: 0;
    }
    .front{
        width: 100%;
        height: 500px;
        padding-top: 0rem;
        padding-left: 0;
    }
    
    .im2{
        width: 100%;
        height: 533px;
    }
    .im1_front_{
    height: 250px;
    width: 100%;
    }
}

@media screen and (min-width:320px) and (max-width:425px) {
    .outer{
        height: 350px;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }
    .front_main{
        width: 100%;
        height: 600px;
        padding-bottom: 0;
    }
    .front{
        width: 100%;
        height: 500px;
        padding-top: 0rem;
        padding-left: 0;
    }
    
    .im2{
        width: 100%;
        height: 533px;
    }
    .im1_front_{
    height: 250px;
    width: 100%;
    }
}