.photo_img{
    height: 450px;
    width: 700px;
    margin-left: 25rem;
    display: flex;
    flex-direction: column;
    align-items:flex;
    justify-content:flex-start;
}


.photo_space{
    width: 100%;
    min-height: 100px;
    padding-top: 3rem;
}


@media screen and (min-width:800px) and (max-width:1025px) {
    .photo_img{
        margin-left: 4rem;
        height: 450px;
        width: 700px;
    }
    .photo_space{
        padding-top: 1rem;
    }
}
@media screen and (min-width:769px) and (max-width:800px) {
    .photo_img{
        margin-left: 10rem;
        height: 450px;
        width: 700px;
    }
    .photo_space{
        padding-top: 1rem;
    }
}
@media screen and (min-width:425px) and (max-width:768px) {
    .photo_img{
        margin-left: 2.5rem;
        height: 450px;
        width: 700px;
    }
    .photo_space{
        padding-top: 1rem;
    }
}

@media screen and (min-width:376px) and (max-width:426px) {
    .photo_img{
        margin-left: 0;
        padding-left: 3rem;
        height: 240px;
        width: 350px;
    }
    .photo_space{
        padding-top: 1rem;
    }
}


@media screen and (min-width:320px) and (max-width:376px) {
    .photo_img{
        margin-left: 0;
        padding-left: 2rem;
        height: 240px;
        width: 350px;
    }
    .photo_space{
        padding-top: 1rem;
    }
}