body {
  margin: 0;
  font-family: 'Outfit', sans-serif;
font-family: 'Poppins', sans-serif;
  background-color:white;
  color: black;
  width: 100vw;

  @media screen and (min-width:784px) and (max-width:1025px){
    margin-right: 5rem;
  }

}




code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');