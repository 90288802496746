.tabl{
    margin: 3rem;
}
.center{
    display: flex;
    flex-direction: column;
}
.images_equip{
  margin-top: 5rem;
  display: flex;
  flex-direction: column;
  height: 300px;
  width: 600px;
}
.heading_table{
    justify-items: center;
    align-self: center;
    text-align: center;
    color: red;
    font-weight: 600;
    font-size: 25px;
    background-color: rgb(231, 231, 231);
    border: 2px solid #858585;
    border-radius: 5px;
    width: 300px;
}

.space{
    background-color: rgb(255, 255, 255);
    color: white;
}

table {
    font-family: 'Trebuchet MS', Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }
  
  table td,
  table th {
    border: 1px solid #b9b900;
    padding: 8px;
  }
  
  table tr:nth-child(even) {
    background-color: #ecff9e;
  }
  
  table tr:hover {
    background-color: #e9e9e9;
  }
  
  table th, tfoot td {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center;
    background-color: #fac0fd;
    color: rgb(0, 0, 0);
  }