.foot_extra{
    width: 100%;
    min-height: 404px;
    color:white;
    background-color:#262525;
}

.footer{
    display: flex;
}

.map{
    padding-top: 3rem;
    padding-left: 8rem;
}
iframe{
    width:500px ;
    height:350px;
}

.footer_class{
    margin-left: 4rem;
    margin-right: 4rem;
}
.footer_main{
    font-family: "Nunito Sans", sans-serif;
    font-size: 16px;
    text-decoration: none solid rgb(255,255,255);
    height: 136px;
    width: 100%;
    margin: 32px 0 0 0;
    display: flex;
    flex-direction: column;

}
.aicte{
    font-size: 28px;
    font-weight: 700;
    text-decoration: none solid rgb(255,255,255);
    height: 42px;
    width: 100%;
    display: block;
    padding: 0 0 5px 0;
    box-sizing: border-box;
}
.college{
    font-size: 24px;
    font-weight: 600;
    text-decoration: none solid rgb(255,255,255);
    display: block;
    padding: 0 0 5px 0;
    box-sizing: border-box;
    word-spacing: 0;
}
.email{
    font-size: 16px;
    font-weight: 600;
    text-decoration: none solid rgb(255,255,255);
    width: 100%;
    display: block;
    padding: 0 0 5px 0;
    box-sizing: border-box;
}
.adress{
    font-size: 16px;
    font-weight: 600;
    text-decoration: none solid rgb(255,255,255);
    width: 100%;
    display: block;
    padding: 0 0 5px 0;
    box-sizing: border-box;
}


.foot_spit_logo{
    width: 100%;
    height: 100px;
    align-items: center;
    align-self: center;
    
}
.footer_start{
    padding-top: 2rem;
    height: 132px;
    width: 100%;
    border-bottom: 2px solid white;
    display: flex;
    justify-content:space-between;
}


.footer_last_footer{
    padding-top:30px;
    height: 23;
    display: flex;
    justify-content: center;
    font-size: 17px;
    padding-left: 0rem;
}

.name{
    padding-left: 18rem;
    font-size: 12px;
}
.map{
    padding-top: 3rem;
    padding-left: 16rem;
    padding-right: 2rem;
}

@media screen and (min-width:1024px) and (max-width:1280px) {
    .footer_last_footer{
        display: flex;
        flex-direction: row;
        text-align: center;
        padding-left: 5rem;
    }
    .name{
        font-size: 10px;
        padding-left: 18rem;
    }
}
@media screen and (min-width:1600px) and (max-width:2560px){
    .footer_main{
        width: 100%;
    }
    .foot_extra{
        width: 100%;
        min-height: 600px;
    }
    iframe{
        width:600px ;
        height:500px;
    }
    .footer_last_footer{
        display: flex;
        flex-direction: row;
        text-align: center;
        padding-left: 5rem;
    }
    .name{
        font-size: 8px;
        padding-left: 10rem;
    }
    .map{
        padding-top: 3rem;
        padding-left: 40rem;
    }
    .aicte{
        font-size: 60px;
    }
    .college{
        font-size: 50px;
    }
    .email{
        font-size: 30px;
    }
    .adress{
        font-size: 30px;
    }
    
}

@media screen and (max-width:1024px) {
    
    iframe{
        width:400px ;
        height:320px;
    }
    .footer{
        display: flex;
        flex-direction: row;
    }
    .footer_last_footer{
        display: flex;
        flex-direction: row;
        text-align: center;
        padding-left: 5rem;
    }
    .name{
        font-size: 8px;
        padding-left: 10rem;
    }
    .map{
        padding-top: 3rem;
        padding-left: 5rem;
    }
}


@media screen and (max-width:820px) {
    .foot_extra{
        width: 100%;
        min-height: 850px
    }
    iframe{
        width:500px ;
        height:400px;
    }
    .footer{
        display: flex;
        flex-direction: column;
    }
    .footer_last_footer{
        display: flex;
        flex-direction: column;
        text-align: center;
        padding-left: 3rem;
    }
    .name{
        font-size: 8px;
        padding-left: 36rem;
    }
    .map{
        padding-left: 10rem;
    }
    
}
@media screen and (max-width:768px) {
    .foot_extra{
        width: 100%;
        min-height: 850px
    }
    iframe{
        width:500px ;
        height:400px;
    }
    .footer{
        display: flex;
        flex-direction: column;
    }
    .footer_last_footer{
        display: flex;
        flex-direction: column;
        text-align: center;
        padding-left: 3rem;
    }
    .name{
        font-size: 8px;
        padding-left: 36rem;
    }
    .map{
        padding-left: 10rem;
    }
    
}
@media screen and (max-width:600px) {
    .foot_extra{
        width: 100%;
        min-height: 800px
    }
    iframe{
        width:500px ;
        height:400px;
    }
    .footer{
        display: flex;
        flex-direction: column;
    }
    .footer_last_footer{
        display: flex;
        flex-direction: column;
        text-align: center;
        padding-left: 3rem;
    }
    .name{
        font-size: 8px;
        padding-left: 36rem;
    }
    .map{
        padding-left: 4rem;
    }
    
}

@media screen and (max-width:540px) {
    .foot_extra{
        width: 100%;
        min-height: 800px
    }
    iframe{
        width:400px ;
        height:400px;
    }
    .footer{
        display: flex;
        flex-direction: column;
    }
    .footer_last_footer{
        display: flex;
        flex-direction: column;
        text-align: center;
        padding-left: 3rem;
    }
    .name{
        font-size: 8px;
        padding-left: 36rem;
    }
    .map{
        padding-left: 4rem;
    }
    
}
@media screen and (min-width:374px) and (max-width:426px) {
    .foot_extra{
        width: 100%;
        min-height: 800px
    }
    
    iframe{
        margin-left: 0;
        padding-left: 0;
        width:300px ;
        height:350px;
    }
    .footer{
        display: flex;
        flex-direction: column;
    }
    .footer_last_footer{
        display: flex;
        flex-direction: column;
        text-align: center;
        padding-left: 0rem;
        font-size: 12px;
    }
    .name{
        text-align: center;
        padding-left: 15rem;
        font-size: 8px;
    }
    .map{
        padding-top: 3rem;
        padding-left: 2.5rem;
    }
    
.aicte{
    font-size: 20px;
}
.college{
    font-size: 16px;
}
.email{
    font-size: 12px;
}
.adress{
    font-size: 12px;
}

    
    
    
}

@media screen and (min-width:320px) and (max-width:376px) {
    .foot_extra{
        width: 100%;
        min-height: 780px
    }
     
    iframe{
        margin-left: 0;
        padding-left: 0;
        width:280px ;
        height:300px;
    }
    .footer{
        display: flex;
        flex-direction: column;
    }
    .footer_last_footer{
        display: flex;
        flex-direction: column;
        text-align: center;
        padding-left: 0rem;
        font-size: 12px;
    }
    .name{
        text-align: center;
        padding-left: 11rem;
        font-size: 8px;
    }
    .map{
        padding-top: 3rem;
        padding-left: 1.2rem;
    }
    
.aicte{
    font-size: 20px;
}
.college{
    font-size: 16px;
}
.email{
    font-size: 12px;
}
.adress{
    font-size: 12px;
}
}