
.heading_table_up{
    justify-items: center;
    align-self: center;
    text-align: center;
    color: red;
    font-weight: 600;
    font-size: 22px;
    background-color: rgb(231, 231, 231);
    border: 2px solid #858585;
    border-radius: 5px;
    width: 300px;
    margin-top: 3rem;
    margin-left: 3rem;
}

.tabl_up{
  margin-right: 3rem;
  margin-left: 3rem;
  padding-bottom: 3rem;
  font-size: 15px;
}
.center_up{
  display: flex;
  flex-direction: column;
}

.space_up{
  background-color: rgb(255, 255, 255);
  color: white;
}

.table_up {
  font-family: 'Trebuchet MS', Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

table td,
table th {
  border: 1px solid #b9b900;
  padding: 8px;
}

table tr:nth-child(even) {
  background-color: #ecff9e;
}

table tr:hover {
  background-color: #e9e9e9;
}

table th, tfoot td {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: #fac0fd;
  color: rgb(0, 0, 0);
}


@media screen and (min-width:380px) and (max-width:426px) {
  .heading_table_up{
    margin-top: 3rem;
    margin-left:2rem;
}
  .tabl_up{
    margin-right: 3rem;
    margin-left:1rem;
    padding-bottom: 3rem;
    font-size: 15px;
  }
}
@media screen and (min-width:320px) and (max-width:376px) {
  .tabl_up{
    margin-right: 3rem;
    margin-left:0.4rem;
    padding-bottom: 3rem;
    font-size: 15px;
  }
}

@media screen and (max-width:325px) {
  .heading_table_up{

    font-size: 22px;

    width: 280px;
}
  .tabl_up{
    margin-right: 3rem;
    margin-left:1.3rem;
    padding-bottom: 3rem;
    font-size: 12px;
  }
}