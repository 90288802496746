.work-container{
    width: 100%;
    min-height: 1000px;
    border-top: solid #262525 0.8px;
}

.temp_heading{
        justify-items: center;
        align-self: center;
        text-align: center;
        color: rgb(120, 130, 204);
        font-weight: 600;
        font-size: 25px;
        background-color: rgb(231, 231, 231);
        border: 2px solid #858585;
        border-radius: 5px;
        width: 400px;
}

.temp_center{
    padding-top: 2rem;
    display: flex;
    flex-direction: column;
}



.project-heading{
    text-align: center;
    padding: 4rem 0 2rem 0;
}

.project-container{
    max-width: 1140px;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-gap: 40px;
    padding-bottom: 5rem;
}

.project-card{
    background: black;
    padding: 1.2rem 1rem;
    background:rgb(218, 216, 216);
    border-radius: 10px;
    box-shadow: 0px 1px 10px 1px black;
}

.project-card img{
    width: 100%;
    border: 1px solid rgb(0, 0, 0);
}

.project-title,.project-duration{
    color: rgb(0, 0, 0);
}

.pro-details p{
    padding-bottom: 1rem;
    font-size:1.1rem;
    text-align: justify;
    font-size: 15px;
    line-height: 15px;
}

.pro-btns{
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 0;
}

.pro-btns .btn{
    padding: 0.5rem 1rem;
}
.btn{
    padding: 12px 32px;
    font-size: 1 rem;
    text-decoration: uppercase;
    background: rgb(113, 106, 160);
    color: black;
    border: px solid rgb(0, 0, 0);
    font-weight: 600;
    cursor:pointer;
    border-radius: 10px;
    transition: all 0.2s ease;
  } 


  @media screen and (min-width:800px) and (max-width:1024px) {
    .project-container{
        grid-template-columns: repeat(2,1fr);
    }
    .project-heading{
        text-align: center;
        padding: 4rem 0 2rem 0;
        padding: 2rem;
    }
}

  @media screen and (min-width:768px) and (max-width:800px) {
    .project-container{
        grid-template-columns: repeat(3,1fr);
    }
    .project-heading{
        text-align: center;
        padding: 4rem 0 2rem 0;
        padding: 2rem;
    }
}
@media screen and (min-width:520px) and (max-width:768px) {
    .project-container{
        grid-template-columns: repeat(2,1fr);
    }
    .project-heading{
        text-align: center;
        padding: 4rem 0 2rem 0;
        padding: 4rem;
    }
}

  @media screen and (min-width:425px) and (max-width:520px) {
    .project-container{
        grid-template-columns: repeat(1,1fr);
    }
    .project-heading{
        text-align: center;
        padding: 4rem 0 2rem 0;
        padding: 4rem;
    }
}

@media screen and (min-width:320px) and (max-width:425px) {

    .temp_heading{
justify-items: center;
align-self: center;
text-align: center;
color: rgb(120, 130, 204);
font-weight: 600;
font-size: 25px;
    color: rgb(120, 130, 204);
border: 2px solid #858585;
border-radius: 5px;
width: 300px;
}
    .project-container{
        grid-template-columns: repeat(1,1fr);
        width: 300px;

    }
    .project-heading{
        text-align: center;
        padding: 4rem 0 2rem 0;
        padding: 1.5rem;
    }
}